import './run_lenis.js'

import m from 'mithril/mithril'
import {Loading, deepCopy, swapClass} from './helpers.js'
import {Mood} from './mood.js'
import { lenis } from './run_lenis.js'
// import {SniffPin}  from  './sticky_header.js'

const init_query = Object.freeze({
    'sort' : 0,
    'type' : null,
    'offset' : 1,
    // 'developer' : null,
    'group' : null,
    'pricing' : null,
    'search' : null
})

var Data = {
    init : false,
    selected_addons: {
        data: [],
        error: "",
        items: [],
        fetch: function (id) {
            Loading.state = true;
            m.request({
                method: "GET",
                url: '/wp-json/2046/v1/selected_addons/'
            })
                .then(function (data) {
                    Data.selected_addons.items = data
                    Loading.state = false;
                })
                .catch(function (e) {
                    Data.selected_addons.error = e.message
                    Loading.state = false;
                })
        }
    },
	addons : {
	    items : [],
	    error : '',
        found_posts : null,
        query : deepCopy(init_query),
        fetch: function() {
            let params = null
            if(!Data.init){
                // getting the date from global scope, real url params and the like
                const mergedParams = { ...init_query, ...global_filter_params };
                Data.addons.query = mergedParams
                params = url_params(mergedParams)
                Data.init = true
            }else if(!is_filter){
                params = url_params(Data.addons.query)
                const home_url = '/' + params
                window.open(home_url)
                return
            }else{
                // the offset need to be on every query set to 1
                // as the pagination is done on loadMore only
                params = url_params(Data.addons.query)
            }
            
            Data.addons.query.offset = 1
            
        
            update_url(params);
            console.log('fetching: ', params)
            Loading.state = true;
            m.request({
                method: "GET",
                url: '/wp-json/2046/v1/addons/' + params, // /wp-json/2046/v1/addon/17909
            })
            .then(function(data) {
                Data.addons.items = data.items ? data.items : []
                Data.addons.found_posts = data.found_posts ? data.found_posts : 0 
                Loading.state = false;
            })
            .catch(function(e) {
                Data.addons.error = e.message
                Loading.state = false;
            })

        },
        loadMore: function() {
            if(Data.addons.items.length < Data.addons.found_posts){
                Data.addons.query.offset = Data.addons.query.offset + 1
                Loading.state = true;
                m.request({
                  method: "GET",
                    url: '/wp-json/2046/v1/addons/' + url_params(Data.addons.query),
                }).then((data) => {
                    if(data.items.length){
                        Data.addons.items = Data.addons.items.concat(data.items)
                        Data.addons.found_posts = data.found_posts ? data.found_posts : 0 
                        // force to redraw
                        m.redraw();
                    }
                    Loading.state = false;
                }).catch(function(e) {
                    Data.addons.error = e.message
                    Loading.state = false;
                })
            }
          },
	},
    addon:{
        data : [],
        error: "",
        fetch: function(id) {
            Loading.state = true;
            m.request({
                method: "GET",
                url: '/wp-json/2046/v1/addon/' + id, // /wp-json/2046/v1/addon/17909
            })
            .then(function(data) {
                Data.addon.data = data.item
                Loading.state = false;
            })
            .catch(function(e) {
                Data.addon.error = e.message
                Loading.state = false;
            })
        }
    },
	pricing : {
		items : [
            {'id' : 'free', 'name' : 'free'},
            {'id' : 'freemium', 'name' : 'freemium'},
            {'id' : 'paid', 'name' : 'paid'}
        ]
	},
	sort : {
        items: [
            {'id' : 0, 'name' : 'newest'},
            {'id' : 1, 'name' : 'oldest'},
            {'id' : 2, 'name' : 'a-z'},
            {'id' : 3, 'name' : 'z-a'}
        ]
	},
    // developer : {
    //     items : [],
    //     error: "",
    //     fetch: function() {
    //         m.request({
    //             method: "GET",
    //             url: '/wp-json/2046/v1/addon_authors/'
    //         })
    //         .then(function(data) {
    //             Data.developer.items = data.items
    //         })
    //         .catch(function(e) {
    //             Data.developer.error = e.message
    //         })
    //     }
    // },
    group : {
        items : [],
        error: "",
        fetch: function() {
            m.request({
                method: "GET",
                url: '/wp-json/2046/v1/addon_type/'
            })
            .then(function(data) {
                Data.group.items = data.items
            })
            .catch(function(e) {
                Data.group.error = e.message
            })
        }
    }
}
function url_params(query){
    var searchParams = new URLSearchParams();
    for (var key in query) {
      if (query.hasOwnProperty(key) && query[key] !== null && query[key] !== undefined && query[key] !== "") {
        searchParams.append(key, query[key]);
      }
    }
    var params = searchParams.toString() != '' ? '?' + searchParams.toString() : '';
    return params
}

function update_url(params){
    let url = window.location.href;
    url = url.split('?')[0] + params;
    window.history.pushState({ path: url }, '', url);
}


var Pricing = {
    view: function(vnode) {
        return m(SelectBox, {model : Data.pricing, selector : 'pricing', label : 'pricing', all_value : {id:'', name: 'all'} })
    }
}

var Categories = {
    oninit: Data.group.fetch,
    view: function(vnode) {
        return m(SelectBox, {model : Data.group, selector : 'group', label : 'categories', all_value : {id:'', name: 'all'}})
    }
}

// var Developer = {
//     oninit: Data.developer.fetch,
//     view: function(vnode) {
//         return m(SelectBox, {model : Data.developer, selector : 'developer', label : 'author', all_value : {id:'', name: 'all'} })
//     }
// }


var Order = {
    view: function(vnode) {
        return m(SelectBox, {model : Data.sort, selector : 'sort', label : 'order', all_value: null })
    }
}

var SelectBox = {
    view: function(vnode) {
        var model = vnode.attrs.model ? vnode.attrs.model : {}
        var selector = vnode.attrs.selector ? vnode.attrs.selector : ''
        var label = vnode.attrs.label ? vnode.attrs.label : ''
        var all_value = vnode.attrs.all_value ? vnode.attrs.all_value : null

        return  m('.fieldset',{},[
            m('label',{}, label),
            m('select', {
                    onchange: function(e){
                        Data.addons.query[selector] = e.target.value
                        Data.addons.fetch()
                    }
                },
                [
                    all_value && m('option', {value : all_value.id}, all_value.name),
                    model.items.map(function(item){
                        return m('option', {
                            value : item.id,
                            selected: Data.addons.query[selector] == item.id
                        }, item.name )
                    })
                ]
            )
        ])
    }

}




var Search = {
    view: function(vnode) {
        var model = Data.addons
        return m('.fieldset', {},[
            m('label', m.trust('&nbsp;')),
            m('input.search', {
                value: model.query.search,
                onchange: function(e) {
                    model.query.search = e.target.value;
                },
                oninput: function(e) {
                    e.preventDefault()
                    model.query.search = e.target.value;
                    if(model.query.search.length > 2){
                        Data.addons.fetch()
                    }
                },
                onkeydown: function (e) {
                    if (e.keyCode === 13) {
                        e.preventDefault()
                    }
                }
            }, model.query.search ),
            // m('input.submit', {
            //     'type' : 'submit',
            //     'value' : 'Search'
            // })
        ])
    }
}


function reset_filter(){
    Data.addons.query = deepCopy(init_query);
    Data.addons.fetch()
}
function isMobile() {
    return /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
function rescroll(id){
    const root = document.documentElement;
    const offset = document.querySelector('#navbar').clientHeight + 36;
    console.log(offset)
    root.style.setProperty('--top-offset', offset + 'px');
    if (!isMobile()) {
        setTimeout(() => {
            const targetElement = document.querySelector("#" + id);
            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;
            lenis.scrollTo(targetPosition)
        }, 400)
    }
}
var Reset = {
    view: function(vnode) {
        return m('.fieldset', {},[
            m('input.reset', {
                type: 'reset',
                onclick: function(e) {
                    reset_filter();
                }
            },'reset')
        ])
    }
}


var FilterControl = {
    oninit: function(){
        // Data.developer.fetch
    },
    view: function(vnode) {
        return m('form.fields', {},[
            m(Categories),
            m(Pricing),
            // m(Developer),
            m(Search),
            m(Reset),
            m(Order)
        ])
    }
}




var ImageGallery = {
    view: function(vnode) {
        return m('.image_gallery', [
            vnode.attrs.image_gallery.map(function(val, index) {
                return m('img',{
                    'id' : 'img_' + val.id,
                    'src': val.medium[0]
                })
            })
        ])
    }
}
var Links = {
    view: function(vnode) {
        var item = vnode.attrs.item
        if(item.links instanceof Object){
           return m('.action_buttons', [
                 item.links.map(function(val, index) {
                    return val.url && m('a.btn.btn-primary', {
                        'href' : val.url,
                        'target' : '_blank',
                        'rel' : 'nofollow'
                    }, val.title)
                }),
                m('a.btn.btn-primary.detail_link', {href: item.url}, ['', m('i.gg-link', '')])
            ])
        }
    }
}

// enlarged addon detail element
var AddonDetail = {
    view: function(vnode) {
        var model = Data.addon.data
        return model.post_title && m('.addon_detail', {
            'data-lenis-prevent': '',
            id: 'addon_detail',
        },[
            m('.content', [
                m('.close_holder', [
                    m('button.close', {
                        onclick: function(){
                            Data.addon.data = []
                        }
                    }, '⨉')
                ]),
                m('.in.cont', [
                    m('a', {href : model.url},[
                        m('img.featured_image',{
                            src: model.featured_image.large ? model.featured_image.large[0] : 'noimage',
                            alt : model.title
                        } ),
                    ]),
                    m('.head', {}, [
                    m(Links, {item: model}),
                    m('h1',  [
                        m('a', {href : model.url, target: '_blank'}, [
                            m('span.title', model.post_title),
                        ]),
                    ]),
                    // m('a.link_detail', {href : model.url}, [m('i.gg-link')]),
                    m('.short_description',  model.short_description),
                    m(Credits, {item: model}),
                        model.types && m('.type', model.types.map(function(cat, index){
                            var sufix = index === model.types.length - 1 ? '' : ', '
                            return m('span.cat', cat.name + sufix)
                        }))
                    ]),
                    model.post_content ? m('.full_content', m.trust(model.post_content)) : m('.content_loading', [m('.loading')] ),
                    model.video_link ? m('.video_link', m.trust(model.video_link)) : '',
                    model.description_features ? m('.description_features', m.trust(model.description_features)) : '',
                    model.image_gallery ? m(ImageGallery, {'image_gallery' : model.image_gallery}) : ''
                ])
            ])
        ])
    }
}
var Credits = {
    view: function(vnode){
        var item = vnode.attrs.item
        return m('.credits', [
            item.authors && item.authors.length > 0 && item.authors.map(function(author){        
                return m('.authors', [
                    m('a', {
                        href: '/?developer=' + author.id
                    } , [
                        m('img.author_logo', {src: author.featured_image.thumbnail[0]}),
                        m('.author', m.trust(author.post_title))
                    ])
                ])
            }),
            m('.pricing', item.pricing)
        ])
    }
}
var AddonCard = {
    oninit: function(){
    },
    oncreate: function(vnode){

    },
    view: function(vnode) {
        var item = vnode.attrs.item
        var is_active = Data.addon.data !== null && Data.addon.data.id !== null && Data.addon.data.id == item.id
        var cid = 'addon_card_' + item.id
        return m('.item.link', {
            class : is_active ? 'active' : '',
            id: cid,
            onclick: function(e){
                Data.addon.data = item
                Data.addon.fetch(item.id)
                rescroll(cid)
            }
        }, [
            m('.in', [
                m('img.featured_image',{
                    src: item.featured_image.thumbnail ? item.featured_image.thumbnail[0] : 'noimage',
                    alt: item.post_title,
                    width: '480px',
                    height: '270px'
                } ),
                m('h2.title._h5', [
                    item.post_title,
                    m('a.btn.detail_link', {
                        href: item.link,
                        onclick: function(event){
                            event.preventDefault()
                            window.open(item.link)
                        }
                    },[
                        m('i.gg-link', '')
                    ]) 
                ]),
                m('.neck', [
                    m('.short_description', item.short_description),
                    item.types && m('.type', item.types.map(function(cat, index){
                        var sufix = index === item.types.length - 1 ? '' : ', '
                        if(index >= 4){
                            return m('span','.')
                        }else{
                            return m('span.cat', cat.name + sufix)
                        }
                        // return m('span.cat', cat.name + sufix)
                    }))
                ]),
                m('.foot',[
                    m(Credits, {item})
                ])
            ])
        ])
    }
}
var Addons = {
    oninit: Data.addons.fetch(),
    // oncreate: function(vnode){

    // },
    view: function(vnode) {
        var model = Data.addons
        var shrinked = Data.addon.data !== null && Data.addon.data.length != 0
        return  m('.addons_list', {
            class: shrinked ? 'shrinked' : ''
        },[
            model.items && model.items.map(function(item){
                return m(AddonCard, {item})
            })
        ]
        )
    }
}

var Selected_addons = {
    oninit: Data.selected_addons.fetch(),
    view: function (vnode) {
        var model = Data.selected_addons
        var shrinked = Data.addon.data !== null && Data.addon.data.length != 0

        return  m('.selected_addons_holder',[
                m('b.divider', 'Most desired'),
                m('.selected_addons', {
                    class: shrinked ? 'shrinked' : ''
                }, [
                    model.items && model.items.map(function (item) {
                        return m(AddonCard, { item })
                    })
                ]),
                m('b.divider', 'Recently added')
            ]
        )
    }
}
var FilterContent = {
    view: function(vnode) {
        console.log(Data.addons.query)
        return m('.filter_wrap',[
            (Data.addons.query.offset >= 1 && Data.addons.query.search == null && Data.addons.query.type == null && Data.addons.query.group == null && !Data.addons.query.pricing ) 
            && m(Selected_addons),
            m(Addons),
            m(AddonDetail)
        ])
    }
}

var NavbarToggle = {
    oninit: function(vnode) {
        vnode.state.isActive = false;
      },
    view: function(vnode) {
        return m('button.navbar-toggle.btn',{
            type: 'button',
            onclick: function(event){
                vnode.state.isActive = !vnode.state.isActive;
                swapClass('hbody', 'filter_exposed')
            },
            class: vnode.state.isActive ? 'active' : ''
        })
    }
}



// assign to doom
if(document.getElementById('filter_control') !== null){
    m.mount(document.getElementById('filter_control'), FilterControl);
}

if(document.getElementById('filter_content') !== null){
    m.mount(document.getElementById('filter_content'), FilterContent);
}

if(document.getElementById('mood') !== null){
    m.mount(document.getElementById('mood'), Mood);
}

if(document.getElementById('navbar_toggle') !== null){
    m.mount(document.getElementById('navbar_toggle'), NavbarToggle);
}

// --------- load more functionality -----------

var throttleTimer;
const throttle = (callback, time) => {
  if (throttleTimer) return;
  throttleTimer = true;
  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

const handleInfiniteScroll = () => {
  throttle(() => {
    const filter_element = document.getElementById('filter_content')
    if(filter_element){
        const endOfPage = window.innerHeight + window.pageYOffset >= (document.body.offsetHeight - 400);
        const bottomSpace = filter_element.innerHeight < document.body.offsetHeight - 100
        if (endOfPage || bottomSpace) {
          Data.addons.loadMore()
        }
    }
  }, 300);
};
// on huge monitor the load more is needed basically onload 
window.addEventListener('load', function(){
    setTimeout(function(){
        handleInfiniteScroll()
    }, 2500)
});
// load more once we reach the end with scroll 
window.addEventListener("scroll", handleInfiniteScroll);
