import m from 'mithril/mithril'
var Loading = {
    state: false
};
var Loading =  {
    view: function(vnode){
        return Loading.state && m('.loading', {}, [m('span')])
    }
}
m.mount(document.getElementById("loading"), Loading);


// make a brand new mutable object from the frozen immutable model
const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return the value if it's not an object or is null
  }
  // Create a new object or array to hold the copied values
  const copy = Array.isArray(obj) ? [] : {};
  // Loop through all properties of the original object
  for (const key in obj) {
    // Recursively copy nested objects and arrays
    copy[key] = deepCopy(obj[key]);
  }
  return copy;
};

/////////

function debounce(func, delay) {
  let timerId;
  return function(...args) {
    clearTimeout(timerId);
    timerId = setTimeout(() => func.apply(this, args), delay);
  };
}

function WindowLimit(width) {
  return window.innerWidth >= width
}

const debounceDelay = 300; 
const debouncedResize = debounce(WindowLimit, debounceDelay);

window.addEventListener("resize", debouncedResize);


/////

function removeClass(elementName, className){
  var items = Array.from(document.getElementsByClassName(elementName));

  items.forEach(item => {
    item.classList.remove(className);
  });
}
function swapClass(element_class, cls){
  const element = document.getElementsByClassName(element_class);
  if(element[0].classList.contains(cls)){
    removeClass(element_class, cls)
  }else{
    element[0].classList.add(cls)
  }
}

export { Loading, deepCopy, WindowLimit, swapClass }