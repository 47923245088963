import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()

// lenis.on('scroll', (e) => {
//   console.log(e)
// })

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
export { lenis }

// gallery
function scrollToId(id, delay = 800, options = {offset: -10}){
  var ots = options ?  options : {offset: -10}
  setTimeout(function(){
    lenis.scrollTo('#'+id, ots)
    window.location.hash = id
  }
  , delay)
}

function swapSrc(row, index){
  var imgs = row.getElementsByClassName('image-gallery');
  for (var i = 0; i < imgs.length; i++) {
    var url = imgs[i].getAttribute(index)
    imgs[i].setAttribute('src', url);
  }
}

var options = {
  offset: -10,
}

document.addEventListener('click', function (event) {
  if (event.target.matches('.image-gallery')){
    event.preventDefault();
    var link = event.target.parentNode
    var row = link.closest('.items');
    var id = event.target.getAttribute('id')
    // close gallery
    if(row.matches('.exposed')){
      row.classList.remove('exposed')
      swapSrc(row, 'data-origin')
    }
    // expose gallery images
    else{
      row.classList.add('exposed')
      swapSrc(row, 'data-large')
      options.offset = (document.getElementById('navbar').offsetHeight + 16) * -1
      scrollToId(id, 400, options)
    }
  } else if (event.target.matches('.letters a')) {
    event.preventDefault();
    var letterGroups = document.querySelectorAll('.letter_group');
    letterGroups.forEach(function (letterGroup) {
      letterGroup.classList.remove('active');
    });
    
    var href = event.target.getAttribute('href'); // get href as id
    var fullUrl = window.location.origin + href;
    var url = new URL(fullUrl);
    var id = url.hash.replace('#','');
    options = { offset: 0 }
    scrollToId(id, 400, options)
    
    // Add 'active' class to 'letter_' + id
    var letterElement = document.getElementById('letter_group_' + id);
    letterElement.classList.add('active');
  }

}, false);