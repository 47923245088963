import m from 'mithril/mithril'

function removeClass(elementName, className){
  var items = Array.from(document.getElementsByClassName(elementName));

  items.forEach(item => {
    item.classList.remove(className);
  });

}
// Save data to local storage
function saveDataToLocalStorage(key, value) {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Set the expiration date to one year from now

  const data = {
    value: value,
    expires: expirationDate.getTime(),
  };

  localStorage.setItem(key, JSON.stringify(data));
}

// Retrieve data from local storage
function getDataFromLocalStorage(key) {
  const dataString = localStorage.getItem(key);

  if (dataString) {
    const data = JSON.parse(dataString);

    // Check if the data has not expired
    if (data.expires > Date.now()) {
      return data.value;
    } else {
      // If the data has expired, remove it from local storage
      localStorage.removeItem(key);
    }
  }

  return null;
}
function setBodyMood(mood){
  if(mood != null && mood != false){
    document.body.classList.add('night')
  }else{
    removeClass('hbody', 'night')
  }
}
function defineMood(){
  const mood = getDataFromLocalStorage("blenderaddonsMood");
  setBodyMood(mood)
}


function changeMood(event){
  const mood = getDataFromLocalStorage("blenderaddonsMood");
  saveDataToLocalStorage("blenderaddonsMood", !mood);
  setBodyMood(!mood)
  return mood
}

var Mood = {
	oninit:defineMood(),
    view: function(vnode) {
        return m('button.mood',{
        	onclick: function(event){
        		event.preventDefault()
        		changeMood()
        	}
        }, m('i',{
        	class : getDataFromLocalStorage("blenderaddonsMood") ? 'gg-moon' : 'gg-sun'
        }))
    }
}


export {Mood}
